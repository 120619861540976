body {
  font-family: "Hanken Grotesk", sans-serif;
}
.collapse.show {
  visibility: visible;
}
.modal-backdrop.show {
  opacity: 0.2 !important;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  transform: scale(12);
  cursor: pointer;
}
th {
  text-align: left;
}
